'use client';

import Image, { ImageProps } from 'next/image';
import { TContenfulImageProp } from 'helpers/types';
import useMediaQuery from 'hooks/use-media-query';

type ImageSizeProps = { width: number; height: number } | { fill: boolean; unoptimized: boolean };

export type ImageSource = {
  src: string;
  alt: string;
  'aria-label': string;
  title?: string;
  'data-testid'?: string;
} & ImageSizeProps;

type ResponsiveImageProps = Omit<ImageProps, 'src' | 'alt' | 'width' | 'height' | 'fill'> & {
  desktopImage: ImageSource;
  mobileImage?: ImageSource;
};

const imageLoader = ({ src, width, quality }) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

export const ResponsiveImage = ({ mobileImage, desktopImage, ...props }: ResponsiveImageProps) => {
  const [isDesktop] = useMediaQuery(768);

  const selectedImage = isDesktop ? desktopImage : mobileImage;
  const sizes = props.sizes || '(max-width: 768px) 100vw, 50vw';

  return (
    <Image
      {...selectedImage}
      alt={selectedImage.alt || selectedImage.title}
      aria-label={selectedImage['aria-label'] || selectedImage.title}
      loader={imageLoader}
      sizes={sizes}
      {...props}
    />
  );
};

type FormattedImageOptionsProps = {
  fill?: boolean;
  sizes?: string;
};

export const formatImage = (
  image: TContenfulImageProp | undefined,
  options?: FormattedImageOptionsProps,
): ImageSource => {
  // Fall back to a placeholder image if the image data is missing or incomplete
  if (!image || !(image.url || image.src)) {
    console.warn('Image data is missing or incomplete:', image);
    return {
      src: '', // Provide a fallback placeholder image: /placeholder-image.jpg
      title: 'Image title',
      alt: 'Image Alt',
      'aria-label': 'Image description',
      width: 0,
      height: 0,
      fill: false,
      unoptimized: false,
    };
  }

  // If both width and height are missing, assume the image should be filled.
  // Defensive programming to avoid setting both width/height and fill, as only one should be used.
  const shouldBeFilled = options?.fill || image.width === undefined || image.height === undefined;

  if (shouldBeFilled && !options?.fill) {
    console.warn('Image will be displayed using the fill property due to missing width/height:', image);
  }

  return {
    src: image.url || image.src || '',
    alt: image.alt || image.title || 'No description available',
    title: image.title || undefined,
    'aria-label': image['aria-label'] || image.title || 'No aria label available',
    width: shouldBeFilled ? 0 : image.width,
    height: shouldBeFilled ? 0 : image.height,
    fill: shouldBeFilled,
    unoptimized: shouldBeFilled,
  };
};
