import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDispensaryInfoProps, IZipcodeBasedGeoData, TDispensaryDrawerOpener } from 'helpers/types/common.type';

interface DispensaryListDrawer {
  dispensaryDrawerState: boolean;
  geoCords: IZipcodeBasedGeoData;
  showAsChangeLocation: boolean;
  selectedDispensary: IDispensaryInfoProps;
  drawerOpener: TDispensaryDrawerOpener;
  drawerStartedBy: TDispensaryDrawerOpener;
}

const initialState: DispensaryListDrawer = {
  dispensaryDrawerState: false,
  geoCords: {
    latitude: 0,
    longitude: 0,
    state: '',
    zip: '',
  },
  selectedDispensary: {
    image: {
      url: '',
    },
    title: '',
    timing: null,
    address: { text: '', url: '' },
    phoneNumber: '',
    contactUs: { text: '', url: '' },
  },
  showAsChangeLocation: false,
  drawerOpener: 'GEO_LOCATION',
  drawerStartedBy: 'GEO_LOCATION',
};

const DispensaryListDrawerSlice = createSlice({
  name: 'dispensaryListDrawer',
  initialState,
  reducers: {
    setDispensaryListDrawerState: (state, action: PayloadAction<boolean>) => {
      state.dispensaryDrawerState = action.payload;
    },
    setShowAsChangeDispensary: (state, action: PayloadAction<boolean>) => {
      state.showAsChangeLocation = action.payload;
    },
    setSelectedDispensary: (state, action: PayloadAction<IDispensaryInfoProps>) => {
      state.selectedDispensary = action.payload;
    },
    setDispensaryListGeoState: (state, action: PayloadAction<IZipcodeBasedGeoData>) => {
      state.geoCords = action.payload;
    },
    setDrawerOpenerState: (state, action: PayloadAction<TDispensaryDrawerOpener>) => {
      state.drawerOpener = action.payload;
    },
    setDrawerStartedByState: (state, action: PayloadAction<TDispensaryDrawerOpener>) => {
      state.drawerStartedBy = action.payload;
    },
    clearGeoAndSelectedDispensaryData: (state) => {
      state.geoCords = initialState.geoCords;
      state.selectedDispensary = initialState.selectedDispensary;
      state.showAsChangeLocation = initialState.showAsChangeLocation;
    },
  },
});

export const {
  setDispensaryListDrawerState,
  setShowAsChangeDispensary,
  setSelectedDispensary,
  setDispensaryListGeoState,
  setDrawerOpenerState,
  clearGeoAndSelectedDispensaryData,
  setDrawerStartedByState,
} = DispensaryListDrawerSlice.actions;

export default DispensaryListDrawerSlice.reducer;
