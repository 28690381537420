import accountReducer from './account';
import ageGateReducer from './age-gate';
import chatReducer from './chat-slice';
import cookieSliceReducer from './cookies';
import dispensaryListReducer from './dispensary-list';
import dispensaryListDrawerSliceReducer from './dispensary-list-drawer';
import segmentTrackingReducer from './eventTracking';
import hostSliceReducer from './host';
import loungePopupReducer from './lounge-popup-slice';
import productReducer from './product';
import cartSliceReducer from './product-cart';
import searchReducer from './search';
import snackbarNotificationSlice from './snackbar-notification';
export {
  hostSliceReducer,
  dispensaryListDrawerSliceReducer,
  loungePopupReducer,
  cartSliceReducer,
  segmentTrackingReducer,
  accountReducer,
  dispensaryListReducer,
  productReducer,
  cookieSliceReducer,
  chatReducer,
  snackbarNotificationSlice,
  searchReducer,
  ageGateReducer,
};
